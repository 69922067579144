import { ICinemas, IFormatMovieData, IMovieData, IShows } from "../models/cinema";
import LatLon from "geodesy/latlon-spherical.js";
import { getPreciseDistance } from "geolib";
import moment from "moment";
import _, { sortBy } from "lodash";
import groupBy from 'lodash/groupBy';
import { URLS, IMAGE_URL_CONFIG } from "../constants/url";
import { IRawUserDetails, IUserDetails } from "../models/auth";
import { BRANDS, COUNTRY } from "../constants/app";

export const formatCinemaList = (data: ICinemas[], countryId: COUNTRY) => {
    const transform = (row: ICinemas) => {
        const tempRow = row;
        tempRow['id'] = row['slug'];
        tempRow['cinemaId'] = row['slug'];
        tempRow['name'] = row['name'];
        tempRow['cinemaName'] = row['name'];
        tempRow['timeZone'] = row['gmt_offset'];
        tempRow['alias'] = row['slug']
        return tempRow;
    };
    return groupBy(data.filter(v=>((countryId === COUNTRY.STA && v.slug === "State") || (countryId === COUNTRY.ANG && v.slug === "southcity") || (countryId === COUNTRY.AUS || countryId === COUNTRY.NZ ))).map(transform), row => row['stateCode']);
}

export const formatLocation = (cinemas: { [key: string]: ICinemas[] }, excludePatentLocation = false, brandId?: BRANDS) => {
    let list: any = [];
    Object.keys(cinemas)
        .sort((a: any, b: any) => (a > b ? 1 : -1))
        .forEach((cinemaId: string) => {
            if (!excludePatentLocation) {
                list.push({
                    label: cinemaId,
                    value: cinemaId,
                    isParent: true,
                });
            }

            cinemas[cinemaId].forEach((v: ICinemas) => {
                list.push({
                    label: brandId === BRANDS.US ? `${v.name}, ${v.city}, ${v.stateCode}` : v.cinemaName,
                    value: v.slug,
                    alias: v.alias,
                    isParent: false,
                });
            });
        });
    if (brandId === BRANDS.US) {
        list = list.sort((a: any, b: any) => {

            let fa = a.label,
                fb = b.label;

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;

        });
    }
    return list;
}

export const findCinemaObject = (cinemas: { [key: string]: ICinemas[] }, value: string) => {

    return _.find(
        _.find(cinemas, function (o: any) {
            return _.find(o, function (p: any) {
                return p.slug == value || p.alias == value;
            });
        }),
        function (i: ICinemas) {
            return i.slug == value || i.alias == value;
        }
    );
}

export const formatCinemaNearby = (position: any, cinemas: any) => {
    let allLocations: any = [];
    let userLocation: any;

    let { latitude, longitude } = position.coords;
    userLocation = new LatLon(latitude, longitude);

    for (const key in cinemas) {
        if (Object.prototype.hasOwnProperty.call(cinemas, key)) {
            const element = cinemas[key];
            element.forEach((element: any) => {
                allLocations.push(element);
            });
        }
    }

    let allDistance: any = [],
        allLocationWithDistance: any = [],
        nearestLocation = null,
        nearestLocationWithName = null,
        lat = null,
        lon = null;

    allLocations.forEach((e: any) => {
        lat = Number(e.latitude);
        lon = Number(e.longitude);

        let locationLatLon = new LatLon(lat, lon);
        let distance = getPreciseDistance(
            {
                latitude: locationLatLon?.lat,
                longitude: locationLatLon?.lon,
            },
            { latitude: userLocation?._lat, longitude: userLocation?._lon }
        );

        let locationWithDistance = {
            name: e.slug,
            distance: distance,
        };
        allLocationWithDistance.push(locationWithDistance);
        allDistance.push(distance);
    });

    nearestLocation = Math.min.apply(null, allDistance);
    nearestLocationWithName =
        allLocationWithDistance[allDistance.indexOf(nearestLocation)];

    return nearestLocationWithName.name;
}

export const formatFlags = (row: any) => {
    const flags: Array<any> = [];
    ["flag1", "flag2", "flag3"].forEach((key) => {
        if (row[key]) {
            if (row[key] !== null) {
                flags.push(JSON.parse(row[key].replace(/\"/g, '"')));
            }
        }
    });
    return flags;
};

const getImageFormat = (row: string, imageType: string) => {
    let imageURL: string = "";
    switch (imageType) {
        case "xsmall":
            imageURL = IMAGE_URL_CONFIG.XSMALL_IMAGE_EXT;
            break;
        case "small":
            imageURL = IMAGE_URL_CONFIG.SMALL_IMAGE_EXT;
            break;
        case "medium":
            imageURL = IMAGE_URL_CONFIG.MEDIUM_IMAGE_EXT;
            break;
        case "large":
            imageURL = IMAGE_URL_CONFIG.LARGE_IMAGE_EXT;
            break;
        case "original":
            imageURL = IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT;
            break;
    }
    return `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${row}${imageURL}`;
}

const statusfill = (row: any) => {

    let fillStatus = 5;
    if (
        row["reservedSeating"] === 0 ||
        row["availableSeats"] == null ||
        row["totalNumberOfSeats"] == null
    ) {
        return fillStatus;
    }
    fillStatus = (+row["availableSeats"] / row["totalNumberOfSeats"]) * 100;
    if (fillStatus <= 30) {
        fillStatus = 2;
    } else if (fillStatus > 30 && fillStatus <= 70) {
        fillStatus = 1;
    } else if (fillStatus > 70) {
        fillStatus = 0;
    }

    return fillStatus;
}

export function formatScreenType(result: IMovieData[]) {
    const formattedObj: IFormatMovieData = {};
    result?.map((row: IMovieData) => {
        if (row.slug) {
            let showDate: any;
            row?.showdates &&
                row?.showdates?.map((arraydate) => {
                    showDate = arraydate?.date;
                    if (typeof formattedObj[showDate] === "undefined") {
                        formattedObj[showDate] = {};
                    }
                    if (typeof formattedObj[showDate][row.slug] === "undefined") {
                        formattedObj[showDate][row.slug] = {
                            movieData: Object.assign({}, row),
                            shows: [],
                        };
                        formattedObj[showDate][row.slug]["movieData"]["movie"] =
                            row["name"];
                        formattedObj[showDate][row.slug]["movieData"]["ratingIcon"] =
                            getImageFormat(row["ratingIcon"], "xsmall");
                        formattedObj[showDate][row.slug]["movieData"]["images"] = [
                            {
                                large: getImageFormat(row["imageId"], "large"),
                                medium: getImageFormat(row["imageId"], "medium"),
                                small: getImageFormat(row["imageId"], "small"),
                            },
                        ];
                        let showsArr: IShows[] = [];
                        arraydate?.showtypes.map((type) => {
                            type?.showtimes.map((time) => {
                                const showsData: IShows = {
                                    id: time?.id,
                                    create_date: time?.date_time,
                                    Performance_status_code: time?.statusCode,
                                    movieId: time?.ScheduledFilmId,
                                    reservedSeating: time?.reservedSeating,
                                    session_attributes: type.type,
                                    showdate: time?.date_time.slice(0, 10),
                                    showtime: moment.parseZone(time?.date_time).format("hh:mmA"),
                                    showDateTime: moment.parseZone(time?.date_time).unix(),
                                    flags: formatFlags(time),
                                    seatsFillStatus: statusfill(time),
                                    soldout: time.soldout,
                                };

                                if (
                                    time?.reservedSeating === parseInt("1") &&
                                    time?.availableSeats !== null &&
                                    time?.totalNumberOfSeats !== null
                                ) {
                                    showsData["seatsFillStatus"] = Math.floor(
                                        (1 -
                                            parseInt(time?.availableSeats) /
                                            time?.totalNumberOfSeats) /
                                        0.3
                                    );
                                }
                                showsArr.push(showsData);
                            });
                        });
                        let sortedShows = _.sortBy(showsArr, [
                            function (o: IShows) {
                                return o.showDateTime;
                            },
                        ]);
                        formattedObj[showDate][row.slug]["shows"] = sortedShows
                            ? sortedShows
                            : [];
                    }
                });
        }
    });
    return formattedObj;
}

const formatComingSoonList = (data: IMovieData[]) => {
    const transform = (row: any) => {
        row['movie'] = row['movieName'];
        row['release_date'] = row['release_date'] ? row['release_date'] : '';
        row['ratingIcon'] = `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${row['ratingIcon']}${IMAGE_URL_CONFIG.XSMALL_IMAGE_EXT}`;
        row['images'] = [
            {
                large: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${row['image']}${IMAGE_URL_CONFIG.LARGE_IMAGE_EXT}`,
                medium: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${row['image']}${IMAGE_URL_CONFIG.MEDIUM_IMAGE_EXT}`,
                small: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${row['image']}${IMAGE_URL_CONFIG.SMALL_IMAGE_EXT}`
            }
        ];
        return row;
    };
    data = data.map(transform);
    return data;
};

const formatAdvMoveList = (data: IMovieData[]) => {
    const formattedObj: any = {};
    let i = 0;
    data.forEach((row: any) => {
        formattedObj[i] = {};
        formattedObj[i]['movieData'] = Object.assign({}, row);
        formattedObj[i]['movieData']['movie'] = row['name'];
        formattedObj[i]['movieData']['rating_name'] = row['rating_name'];
        formattedObj[i]['movieData']['ratingDescription'] =
            row['ratingDescription'];
        formattedObj[i]['movieData'][
            'ratingIcon'
        ] = `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${row['ratingIcon']}${IMAGE_URL_CONFIG.XSMALL_IMAGE_EXT}`;
        formattedObj[i]['movieData']['images'] = [
            {
                large: getImageFormat(row['imageId'], 'large'),
                medium: getImageFormat(row['imageId'], 'medium'),
                small: getImageFormat(row['imageId'], 'small')
            }
        ];

        formattedObj[i]['showdates'] = [];
        formattedObj[i]['shows'] = {};
        formattedObj[i]['slug'] = row.slug;
        row['showdates'].forEach((dates: any) => {
            formattedObj[i]['showdates'].push(dates['date']);
            dates['showtypes'].forEach((type: any) => {
                type['showtimes'].forEach((time: any) => {
                    if (!formattedObj[i]['shows'][dates['date']]) {
                        formattedObj[i]['shows'][dates['date']] = [];
                    }
                    const showsData = {
                        Id: time.id,
                        id: time.id,
                        create_date: time.date_time,
                        Performance_status_code: time.statusCode,
                        Movie_id: time.ScheduledFilmId,
                        movieId: time.ScheduledFilmId,
                        reserved_seating: time.reserved_seating || time.reservedSeating,
                        reservedSeating: time.reserved_seating || time.reservedSeating,
                        session_attributes: type.type,
                        showdate: time.date_time.slice(0, 10),
                        showtime: moment.parseZone(time.date_time).format('hh:mmA'),
                        showDateTime: moment.parseZone(time.date_time).unix(),
                        flags: formatFlags(time),
                        seatsFillStatus: statusfill(time)
                    };
                    if (
                        (time.reserved_seating === '1' || time.reservedSeating === '1') &&
                        time.available_seats !== null &&
                        time.totalNumberOfSeats !== null
                    ) {
                        showsData['seatsFillStatus'] = Math.floor(
                            (1 - time.available_seats / time.totalNumberOfSeats) / 0.3
                        );
                    }
                    formattedObj[i]['shows'][dates['date']].push(showsData);
                });
            });
        });
        i++;
    });
    return formattedObj;
}

export const formatComingSoonAdv = (comingSoon: IMovieData[], advanceTicket: IMovieData[]) => {

    // coming soon
    const comingSoonData = comingSoon.filter(data => !advanceTicket.find(v => v.slug === data.slug))
    let currsoonlist = formatComingSoonList(comingSoonData);

    //adv tickets
    let curradvancelist: any = [];
    const res = formatAdvMoveList(advanceTicket);
    curradvancelist = sortBy(res, row => row['movieData']['name']);
    curradvancelist.forEach((movieRow: any) => {
        movieRow['dateArr'] = movieRow.showdates;
        movieRow['sessionArr'] = Object.values(movieRow.shows);
    });
    currsoonlist = [...currsoonlist, ...curradvancelist]

    const cinemaData = {
        standardFlag: false,
        premiumFlag: false,
        titanxcFlag: false,
        titanluxeFlag: false,
        goldFlag: false,
        wcFlag: false
    }

    curradvancelist.forEach((cinemaDetails: any) => {
        cinemaDetails['sessionArr'].map((sessionlegend: any) => {
            sessionlegend.forEach((sessionlegends: any) => {
                const sessionAttributes = sessionlegends['session_attributes'];
                switch (sessionAttributes.toLowerCase()) {
                    case 'standard':
                        cinemaData.standardFlag = true;
                        break;
                    case 'premium':
                        cinemaData.premiumFlag = true;
                        break;
                    case 'titanxc':
                        cinemaData.titanxcFlag = true;
                        break;
                    case 'titanluxe':
                        cinemaData.titanluxeFlag = true;
                        break;
                    case 'gold':
                        cinemaData.goldFlag = true;
                        break;
                    case 'wheelchair':
                        cinemaData.wcFlag = true;
                        break;
                    default:
                        cinemaData.standardFlag = true;
                        break;
                }
            });
        });
    });

    return sortBy(currsoonlist, row => {
        return row.hasOwnProperty('movieData') ? row['movieData']['release_date'] : row['release_date']
    });

}

export const formatMovieData = (movieData: any) => {
    return _.sortBy(movieData, (row: any) => row.movieData.movie);
};

export const formatMovieDetails = (data: any) => {
    const formattedObj: any = {};
    data.forEach((row: any) => {
        if (row['movieGroupId']) {
            const showDate = row['showDateTime'] ? row['showDateTime'].slice(0, 10) : '';
            if (typeof formattedObj[row['movieGroupId']] === 'undefined') {
                formattedObj[row['movieGroupId']] = {};
                formattedObj[row['movieGroupId']] = Object.assign({}, row);
                formattedObj[row['movieGroupId']]['movie'] = row['movieName'];
                formattedObj[row['movieGroupId']]['images'] = [
                    {
                        large: getImageFormat(row['image'], 'large'),
                        medium: getImageFormat(row['image'], 'medium'),
                        small: getImageFormat(row['image'], 'small')
                    }
                ];
                formattedObj[row['movieGroupId']][
                    'ratingIcon'
                ] = `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${row['ratingIcon']}${IMAGE_URL_CONFIG.XSMALL_IMAGE_EXT}`;
                formattedObj[row['movieGroupId']]['showtimes'] = {};
            }
            if (
                typeof formattedObj[row['movieGroupId']]['showtimes'][showDate] ===
                'undefined'
            ) {
                if (showDate) {
                    formattedObj[row['movieGroupId']]['showtimes'][showDate] = [];
                }
            }

            const showData = {
                Id: row['sessionId'],
                id: row['sessionId'],
                create_date: row['showDateTime'],
                Performance_status_code: row['statusCode'],
                Movie_id: row['movieId'],
                movie_id: row['movieId'],
                movieId: row['movieId'],
                reserved_seating: row['reservedSeating'],
                reservedSeating: row['reservedSeating'],
                session_attributes: row['sessionAttributes'],
                showdate: row['showDateTime'] ? row['showDateTime'].slice(0, 10) : '',
                showtime: moment.parseZone(row['showDateTime']).format('hh:mmA'),
                showDateTime: moment.parseZone(row['showDateTime']).unix(),
                flags: formatFlags(row),
                seatsFillStatus: statusfill(row)
            };
            if (row['showDateTime']) {
                formattedObj[row['movieGroupId']]['showtimes'][showDate].push(showData);
            }
        }
    });
    const returnArr = [];
    for (const key in formattedObj) {
        if (key) {
            returnArr.push(formattedObj[key]);
        }
    }

    return returnArr;
};

export const formatAdvanceMovieDetail = (data: any) => {
    const formattedObj: any = {};
    data.forEach((row: any) => {
        if (row['movieGroupId']) {
            if (typeof formattedObj[row['movieGroupId']] === 'undefined') {
                formattedObj[row['movieGroupId']] = {};
                formattedObj[row['movieGroupId']] = Object.assign({}, row);
                formattedObj[row['movieGroupId']]['movie'] = row['movieName'];
                formattedObj[row['movieGroupId']]['images'] = [
                    {
                        large: getImageFormat(row['image'], 'large'),
                        medium: getImageFormat(row['image'], 'medium'),
                        small: getImageFormat(row['image'], 'small')
                    }
                ];
                formattedObj[row['movieGroupId']][
                    'ratingIcon'
                ] = `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${row['ratingIcon']}${IMAGE_URL_CONFIG.XSMALL_IMAGE_EXT}`;
                formattedObj[row['movieGroupId']]['showtimes'] = {};
            }
        }
    });
    const returnArr = [];
    for (const key in formattedObj) {
        if (key) {
            returnArr.push(formattedObj[key]);
        }
    }
    return returnArr;
};

export const formatSearchList = (data: any) => {
    const transform = (row: any) => {
        row['countryId'] = row['countryid'];
        row['competition_image'] = getImageFormat(row['image'], 'medium');
        row['uniqueId'] = row['uniquekey'];
        row['movie'] = row['name'];
        row['outline'] = row['description'];
        row['type'] = row['type'] || 'movies'; // default as movie
        row['rating'] = row['others'];
        row['deal_image'] = getImageFormat(row['image'], 'medium');
        row['title'] = row['name'];
        row['content'] = row['ratingdescription'];
        row['description'] = row['description'];
        if (row['rating']) {
            if (row['type'] === 'Competition') {
                row['questions'] = row['rating'][0];
            }
            if (row['type'] === 'movies') {
                row['rating-sub'] = JSON.parse(row['rating'][0]);
                row['ratingIcon'] = getImageFormat(
                    row['rating-sub']['ratingIcon'],
                    'xsmall'
                );
                row['ratingDescription'] = row['rating-sub']['ratingDescription'];
                row['ratingExplanation'] = row['rating-sub']['ratingExplanation'];
            }
        }
        if (row['type'] === 'movies') {
            row['ratingImage'] = getImageFormat(row['ratingImage'], 'xsmall');
        }
        if (row['type'] === 'competition') {
            row['questions'] = row['question'];
            row['prizeImgId'] = getImageFormat(row['prizeImgId'], 'medium');
        }
        row['images'] = [
            {
                large: getImageFormat(row['image'], 'large'),
                medium: getImageFormat(row['image'], 'medium'),
                small: getImageFormat(row['image'], 'small')
            }
        ];
        return row;
    };
    return data.map(transform);
};

export const formatTicketConfig = (response: any) => {
    const { films: filmsData,
        ticketingTypeLength: ticketTypeLengthData,
        ticketType: ticketingTypeData, userSessionId } = response;
    const {
        data: [films],
    } = filmsData;
    const {
        data: {
            data: { ticketTypeCount },
        },
    } = ticketTypeLengthData;
    const {
        data: {
            data
        },
    } = ticketingTypeData;
    let ticketType = [];
    let seatLayout = [];
    let sharedUserSession = [];
    if (data.ticketType || data.seatLayout) {
        ticketType = data.ticketType;
        seatLayout = data.seatLayout
    }

    if (data.sharedUserSession && data.sharedUserSession.length > 0) {
        sharedUserSession = data.sharedUserSession
    }

    let hasGL = 0;
    if (
        films &&
        films.sessionAttributes &&
        films.sessionAttributes === "Gold"
    ) {
        hasGL = 1;
    }
    return { films, ticketTypeCount, ticketType, seatLayout, hasGL, sharedUserSession, ticketDetails:{userSessionId} };
}

export const formatUserDetails = (userDetails: IUserDetails, countryId: string, cinemaId: string) => {
    return {
        CardNumber: userDetails?.cardNumber!,
        Country_id: countryId,
        DateOfBirth: userDetails?.dateOfBirth!,
        Email: userDetails?.email!,
        FirstName: userDetails?.firstName!,
        Gender: userDetails?.gender!,
        Id: userDetails?.result?.memberRefId!,
        LastName: userDetails?.lastName!,
        MobilePhone: userDetails?.phoneNumber!,
        Online: 1,
        PreferredGenres: userDetails?.preferredGenres!,
        Theatre_id: cinemaId!,
        Weekly_news: userDetails?.weekly_news!,
        ZipCode: userDetails?.zipCode!,
        ios_token: userDetails?.ios_token!,
    }
}

export const formatUser = (data: IRawUserDetails): IUserDetails => {
    return {
        countryId: data.Country_id,
        email: data.Email,
        firstName: data.FirstName,
        id: data.Id,
        lastName: data.LastName,
        phoneNumber: data.MobilePhone,
        theatreId: data.Theatre_id,
        cardNumber: data.CardNumber,
        theatre_id: data.Theatre_id,
        weekly_news: data.Weekly_news,
        online: data.Online,
        vista_memberid: data.vista_memberid,
        zipCode: data.ZipCode,
        dateOfBirth: data.DateOfBirth,
        gender: data.Gender,
        preferredGenres: data.PreferredGenres,
        token: data.token,
        ios_token: data.ios_token,
        result: {
            memberRefId: data.result.MemberRefId,
            theatreId: data.result.theatreId,
            cardNumber: data.result.cardNumber,
            loyaltyPhoneNumber: data.result.loyaltyPhoneNumber
        }
    }
}

export const formaSignupUser = (data: any): IUserDetails => {
    return data.result ? {
        ...data, result: {
            memberRefId: data.result.MemberRefId || data.result.memberRefId,
            theatreId: data.result.theatreId,
            cardNumber: data.result.cardNumber,
            loyaltyPhoneNumber: data.result.loyaltyPhoneNumber
        }
    } : { ...data }
}

export const formatBookedTicket = (data: any) => {
    return data.reduce((acc: any, v: any) => {
        const formatedDate = moment
            (v.sessiondate, 'MMDDYYYY')
            .format('DD-MM-YYYY')
        if (!acc[formatedDate]) {
            acc[formatedDate] = {
                sessionTime: moment
                    (v.sessiondate, 'MMDDYYYY')
                    .format('dddd Do MMMM YYYY'), data: []
            }
        }
        let alcoholFlag: any = 0;
        if (v.State) {
            alcoholFlag = JSON.parse(v.State);
            if (alcoholFlag.alcohol) {
                alcoholFlag = alcoholFlag.alcohol;
            }
        }
        let tabFlag: any = 0;
        if (v.State) {
            tabFlag = JSON.parse(v.State);
            if (tabFlag.itemTabFlag) {
                tabFlag = tabFlag.itemTabFlag;
            }
        }
        acc[formatedDate].data.push({
            movieImage: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.imageId}${IMAGE_URL_CONFIG.LARGE_IMAGE_EXT}`,
            saleId: v.saleId,
            email: v.Email,
            movieName: v.moviename,
            showDateTime: v.showdatetime,
            selectedSeats: v.selected_seats,
            ticketPrice: v.totalprice,
            concessionTotal: v.Extra ? v.Extra.map((v: any) => JSON.parse(v)).reduce((acc: any, v: any) => {
                return acc + Number(v.Price);
            }, 0) : 0,
            concessions: v.Extra ? v.Extra.map((v: any) => JSON.parse(v)).map((v: any) => {
                const smartModifierItem =
                    (v.smartModifierGroups && v.smartModifierGroups.length > 0) ? (v.smartModifierGroups[0].selected) : [];
                v.modifierItem = [...v.modifier, ...smartModifierItem].join(',');
                v.parentId = v.id
                return v;
            }) : [],
            time: moment(v.sessiontime, 'hhmm')
                .format('hh:mm A'),
            date: moment(v.sessiondate, 'MMDDYYYY')
                .format('dddd Do MMMM'),
            theatreId: v.Theatre_id,
            movieId: v.Movie_id,
            id: v.Id,
            imageId: v.imageId,
            hasGl: (v.screenname).match(/gold/i) ? 1 : 0,
            screenName: v.screenname,
            ratingDescription: v.ratingDescription,
            ratingExplanation: v.ratingExplanation,
            ratingIcon: v.ratingIcon,
            ratingName: v.ratingName,
            sessionId: v.Perf_id,
            parentShow: `${moment(v.sessiondate, 'MMDDYYYY')
                .format('YYYY-MM-DD')} ${moment(v.sessiontime, 'hhmm')
                    .format('H:mm:ss')}`,
            alcoholFlag: alcoholFlag,
            tabFlag: tabFlag,
            cinemaName: v.cinemaname
        })
        return acc;
    }, {})
}

export const dateToFromNowDaily = (myDate: string) => {

    // get from-now for this date
    var fromNow = moment(myDate).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment(myDate).calendar(null, {
        // when the date is closer, specify custom values
        lastWeek: '[Last] M/D',
        lastDay: '[Yesterday] M/D',
        sameDay: '[Today] M/D',
        nextDay: '[Tomorrow] M/D',
        nextWeek: 'dddd M/D',
        // when the date is further away, use from-now functionality             
        sameElse: function () {
            return "[" + moment(myDate).format("dddd M/D") + "]";
        }
    });
}

export const seatFillStatus = (available_seats: number, totalNumberOfSeats: number) => {
    let FillStatus = (Number(available_seats) / Number(totalNumberOfSeats)) * 100;
    if (FillStatus <= 30) {
        FillStatus = 2;
    } else if (FillStatus > 30 && FillStatus <= 70) {
        FillStatus = 1;
    } else if (FillStatus > 70) {
        FillStatus = 0;
    }
    return FillStatus;
}

export const formatSignatureSlides = (data: Array<any>) => {
    return data.map((v) => {
        return {
            imageUrl: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.desktopImage}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`,
            mobileImageUrl: `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${v.mobileImage}${IMAGE_URL_CONFIG.ORIGINAL_IMAGE_EXT}`,
            showBannerInfo: 0,
            textColor: "#000",
            title: v.Program_title,
        };
    })
}

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatMovieTypeName = (name: string) => {
    return name.toLowerCase()
        .split(" ")
        .join("_");
}

